import * as React from 'react'
import { useState } from 'react'
import dayjs from 'dayjs'
import { Outlet, useNavigate, useOutletContext } from 'react-router-dom'
import DateSelector from './components/dateSelector'
import Footer from './footer'
import Header from './header'

const App = () => {
  const navigate = useNavigate()

  const [selectedDate, setSelectedDate] = useState<string | null>('')
  const [homePage, setHomePage] = useState<boolean>(true)

  const handleClick = () => {
    if (selectedDate?.length === 10 && dayjs(selectedDate, 'YYYY-MM-DD').isValid()) {
      navigate('news/' + selectedDate)
    } else {
      console.log('error')
    }
  }

  return (
    <>
      <div className="main">
        <Header />
        <div className="main-body">
          <div className="spacer" />
          {homePage && <p className="homepage-description">Discover what made your parents go to bed early!</p>}
          <div className="spacer" />
          <div className={`search-form ${homePage ? 'homepage' : ''}`}>
            <p className={`birthday-text ${homePage ? 'homepage' : ''}`}>Select your date of birth:</p>
            <div className={`date-form ${homePage ? 'homepage' : ''}`}>
              <DateSelector selectedDate={selectedDate} onChangeDate={setSelectedDate} />
              <button type="button" className="submit-button" onClick={handleClick}>
                Go
              </button>
            </div>
          </div>
          <Outlet context={[setHomePage, setSelectedDate]} />
        </div>
        <div className="spacer" />
        <Footer />
      </div>
    </>
  )
}

export function useAppContext() {
  return useOutletContext<Array<Function>>()
}

export default App
