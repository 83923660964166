import fetch from 'node-fetch'
import dayjs from 'dayjs'
import { guardianResultsType, newYorkTimesNewsType, parsedGuardianNewsType, parsedNYTNewsType } from '../types/newsType'
import { getEarlierDate, getEarlierDateNYT } from '../components/dateSelector'

let Sentiment = require('sentiment')

let sentiment = new Sentiment()

export async function fetchApiFull(
  date: string,
  nbResults: number
): Promise<parsedGuardianNewsType[] | parsedNYTNewsType[]> {
  if (date && date.length === 10 && dayjs(date, 'YYYY-MM-DD').isValid()) {
    if (Number(date.slice(0, 4)) > 2000) {
      return fetchGuardianApi(getEarlierDate(date), nbResults)
    } else {
      let list: parsedNYTNewsType[] = []
      const resultPage1 = await fetchNYTApi(getEarlierDateNYT(date), 1)
      list = list.concat(resultPage1)
      const resultPage2 = await fetchNYTApi(getEarlierDateNYT(date), 2)
      list = list.concat(resultPage2)
      return list
    }
  }
}

/**
 *
 * @param selectedDate YYYY-MM-DD
 * @param nbResults default=20
 */
export async function fetchGuardianApi(selectedDate: string, nbResults: number): Promise<parsedGuardianNewsType[]> {
  if (selectedDate !== null) {
    const url =
      'https://www.ninemonthsago.net/api/news/guardian/page-size=' +
      nbResults +
      '&order-by=relevance&from-date=' +
      selectedDate +
      '&to-date=' +
      selectedDate +
      '&show-fields=thumbnail,body'
    const options = {
      method: 'GET',
      headers: {
        Accept: 'text/html, application/json',
      },
    }
    const response = await fetch(url, options)
    const res = await response.json()
    return res.response.results?.map((obj: guardianResultsType): parsedGuardianNewsType => {
      if (obj.fields) {
        return {
          pillarName: obj.pillarName,
          webTitle: obj.webTitle,
          webUrl: obj.webUrl,
          thumbnail: obj.fields.thumbnail,
          abstract: obj.fields.body,
          score: sentiment.analyze(obj.fields.body).score,
          source: 'The Guardian',
        }
      } else {
        return {
          pillarName: obj.pillarName,
          webTitle: obj.webTitle,
          webUrl: obj.webUrl,
          abstract: obj.fields.body,
          score: sentiment.analyze(obj.fields.body).score,
          source: 'The Guardian',
        }
      }
    })
  } else {
    return null
  }
}

/**
 *
 * @param date YYYYMMDD
 * @param page
 */
export async function fetchNYTApi(date: string, page: number): Promise<parsedNYTNewsType[]> {
  const sentiment = new Sentiment()
  const url = 'https://www.ninemonthsago.net/api/news/nyt/begin_date=' + date + '&end_date=' + date + '&page=' + page
  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
  }
  const response = await fetch(url, options)
  const res = await response.json()
  return res.response.docs.map((obj: newYorkTimesNewsType): parsedNYTNewsType => {
    if (obj.multimedia) {
      return {
        abstract: obj.abstract,
        pillarName: obj.section_name,
        webTitle: obj.headline.print_headline,
        webUrl: 'https://translate.google.com/translate?sl=fr&tl=en&u=' + obj.web_url,
        thumbnail: obj.multimedia[0] ? obj.multimedia[0].url : '',
        score: sentiment.analyze(obj.abstract).score,
        source: obj.source,
      }
    } else {
      return {
        abstract: obj.abstract,
        pillarName: obj.section_name,
        webTitle: obj.headline.print_headline,
        webUrl: 'https://translate.google.com/translate?sl=fr&tl=en&u=' + obj.web_url,
        score: sentiment.analyze(obj.abstract).score,
        source: obj.source,
      }
    }
  })
}
