import { createBrowserRouter } from 'react-router-dom'
import App from './webApp/app'
import ErrorPage from './webApp/errorPage'
import ResultPage, { newsLoader } from './webApp/resultPage'
import * as React from 'react'
import { QueryClient } from '@tanstack/react-query'

export const router = (queryClient: QueryClient) =>
  createBrowserRouter(
    [
      {
        path: '/',
        element: <App />,
        errorElement: <ErrorPage />,
        children: [
          {
            path: 'news/',
            children: [
              {
                path: ':newsdate',
                element: <ResultPage />,
                loader: newsLoader(queryClient),
              },
            ],
          },
        ],
      },
    ],
    {
      future: {
        v7_relativeSplatPath: true,
      },
    }
  )
