import * as React from 'react'
import dayjs from 'dayjs'

// returns the date 9 months before - format = YYYY-MM-DD
export function getEarlierDate(date: string): string {
  return dayjs(date, 'YYYY-MM-DD').subtract(9, 'months').format('YYYY-MM-DD')
}

export function getEarlierDateNYT(date: string): string {
  return dayjs(date, 'YYYY-MM-DD').subtract(9, 'months').format('YYYYMMDD')
}

const DateSelector: React.FC<{ onChangeDate: Function; selectedDate: string | null }> = ({
  onChangeDate,
  selectedDate,
}) => {
  const startDate = getLaterDate('01-01-1900')

  function getTodayDate(): string {
    let today = new Date()
    const dd: string = String(today.getDate()).padStart(2, '0')
    const mm: string = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
    const yyyy: number = today.getFullYear()
    return mm + '-' + dd + '-' + yyyy
  }

  function getLaterDate(date: string): string {
    return dayjs(date, 'MM-DD-YYYY').add(9, 'months').format('YYYY-MM-DD')
  }

  return (
    <input
      type="date"
      className="birthday-form"
      value={selectedDate}
      min={startDate}
      max={getLaterDate(getTodayDate())}
      onChange={(event) => onChangeDate(event.target.value)}
      maxLength={10}
      required
    />
  )
}

export default DateSelector
