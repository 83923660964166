import * as React from 'react'

import { parsedGuardianNewsType, parsedNYTNewsType } from '../types/newsType'
import { getThumbnail } from './listOfNews'
import { VscQuestion } from 'react-icons/vsc'

const SentimentalScore = (listOfNews: { listOfNews: parsedGuardianNewsType[] | parsedNYTNewsType[] }) => {
  function getWorstNews(
    listOfNews: parsedGuardianNewsType[] | parsedNYTNewsType[]
  ): parsedGuardianNewsType | parsedNYTNewsType {
    let worstNews: parsedGuardianNewsType | parsedNYTNewsType

    Object.values(listOfNews).forEach((list: parsedGuardianNewsType | parsedNYTNewsType) => {
      if (!worstNews) {
        worstNews = list
      }
      if (list.score < worstNews.score) {
        worstNews = list
      }
    })
    return worstNews
  }

  function getBestNews(
    listOfNews: parsedGuardianNewsType[] | parsedNYTNewsType[]
  ): parsedGuardianNewsType | parsedNYTNewsType {
    let bestNews: parsedGuardianNewsType | parsedNYTNewsType

    Object.values(listOfNews).forEach((list: parsedGuardianNewsType | parsedNYTNewsType) => {
      if (!bestNews) {
        bestNews = list
      }
      if (list.score > bestNews.score) {
        bestNews = list
      }
    })
    return bestNews
  }

  function renderWorstNews(news: parsedGuardianNewsType | parsedNYTNewsType) {
    return (
      <>
        <div className="body-title">
          <div>The coldest news of this day&nbsp;</div>
          <div
            data-text="Lowest score based on sentiment analysis of all the text within this article
            compared to every other articles on this day"
            className="tooltip"
          >
            <VscQuestion className="question-icon" fontSize="22px" />
          </div>
        </div>
        <a key={'item-worst'} className="list-result" href={news.webUrl} target="_blank">
          <img className="list-result-thumbnail" src={getThumbnail(news)} alt={news.webTitle} />
          <p className="list-result-text">{news.webTitle}</p>
          <div className="badge-container">
            <span className="badge badge-blue badge-round">Coldest</span>
            {news.pillarName && <span className="badge badge-default badge-round">{news.pillarName}</span>}
            <span className="badge badge-purple badge-round">{news.source}</span>
          </div>
        </a>
      </>
    )
  }

  function renderBestNews(news: parsedGuardianNewsType | parsedNYTNewsType) {
    return (
      <>
        <div className="body-title">
          <div>The warmest news of this day&nbsp;</div>
          <div
            data-text="Best score based on sentiment analysis of all the text within this article
            compared to every other articles on this day"
            className="tooltip"
          >
            <VscQuestion className="question-icon" fontSize="22px" />
          </div>
        </div>
        <a key={'item-worst'} className="list-result" href={news.webUrl} target="_blank">
          <img className="list-result-thumbnail" src={getThumbnail(news)} alt={news.webTitle} />
          <p className="list-result-text">{news.webTitle}</p>
          <div className="badge-container">
            <span className="badge badge-red badge-round">Warmest</span>
            {news.pillarName && <span className="badge badge-default badge-round">{news.pillarName}</span>}
            <span className="badge badge-purple badge-round">{news.source}</span>
          </div>
        </a>
      </>
    )
  }

  function calculateSentimentalScore(listOfNews: parsedGuardianNewsType[] | parsedNYTNewsType[]) {
    let score: number = 0
    Object.values(listOfNews).forEach((list: parsedGuardianNewsType | parsedNYTNewsType) => {
      score = score + list.score
    })
    return score
  }

  function parseSentimentalScore(sentimentalScore: number) {
    if (sentimentalScore < 0) {
      return (
        <div className="sentimental-title">
          Ouch, this didn't seem to be a positive day overall. They surely had to console themselves.
        </div>
      )
    }
    if (sentimentalScore > 0) {
      return (
        <div className="sentimental-title">
          It looks like it was a positive day overall. They must have celebrated it!
        </div>
      )
    }
    if (sentimentalScore === 0) {
      return <div className="sentimental-title">It looks like it was a 'meh' day overall. Bye bye boredom!</div>
    }
  }

  return (
    <div>
      <div className="sentimental-score">
        Sentimental score:&nbsp;
        <span className="sentimental-score">{calculateSentimentalScore(listOfNews.listOfNews)}&nbsp;</span>
        <div
          data-text="This is a score based on sentiment analysis of all the text within this day's articles"
          className="tooltip"
        >
          <VscQuestion className="question-icon" />
        </div>
      </div>
      {parseSentimentalScore(calculateSentimentalScore(listOfNews.listOfNews))}
      {renderBestNews(getBestNews(listOfNews.listOfNews))}
      {renderWorstNews(getWorstNews(listOfNews.listOfNews))}
    </div>
  )
}

export default SentimentalScore
