import * as React from 'react'
import { useEffect } from 'react'
import dayjs from 'dayjs'
import { LoaderFunctionArgs, useLoaderData, useParams } from 'react-router-dom'
import { useAppContext } from './app'
import { getEarlierDate } from './components/dateSelector'
import ListOfNews from './components/listOfNews'
import SentimentalScore from './components/sentimentalScore'
import { fetchApiFull } from './requests/requests'
import { parsedGuardianNewsType, parsedNYTNewsType } from './types/newsType'
import { QueryClient, useQuery } from '@tanstack/react-query'

const nbResultsExpected = 20

const newsFetchQuery = (newsdate: string, nbResultsExpected: number) => ({
  queryKey: ['newsLoader', newsdate],
  queryFn: async () => fetchApiFull(newsdate, nbResultsExpected),
  retry: 3,
})

export const newsLoader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs): Promise<parsedGuardianNewsType[] | parsedNYTNewsType[]> => {
    const query = newsFetchQuery(params.newsdate, nbResultsExpected)
    return queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
  }

const ResultPage = () => {
  const params = useParams()
  // ⬇️ useQuery as per usual
  const { data: listOfNews } = useQuery(newsFetchQuery(params.newsdate, nbResultsExpected))
  const [setHomePage, setSelectedDate] = useAppContext()
  const date = useParams()
  let earlierDate: string = dayjs(getEarlierDate(date.newsdate), 'YYYY-MM-DD').format('DD/MM/YYYY')

  useEffect(() => {
    setSelectedDate(date.newsdate)
    setHomePage(false)
  }, [listOfNews])

  return (
    <div>
      <div className="date">Was {earlierDate} a good day?</div>
      <SentimentalScore listOfNews={listOfNews} />
      <div className="body-title">All the news</div>
      <ListOfNews listOfNews={listOfNews} />
    </div>
  )
}

export default ResultPage
