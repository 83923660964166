import * as React from 'react'

import { parsedGuardianNewsType, parsedNYTNewsType } from '../types/newsType'
import img1 from '../../style/no-image.png'

export function getThumbnail(list: parsedGuardianNewsType | parsedNYTNewsType): any {
  if (!list.thumbnail) {
    return img1
  }
  return list.thumbnail
}

const ListOfNews = (listOfNews: { listOfNews: parsedGuardianNewsType[] | parsedNYTNewsType[] }) => {
  return (
    <>
      <div className="list">
        {Object.values(listOfNews.listOfNews).map((list: parsedGuardianNewsType | parsedNYTNewsType, index: number) => (
          <a key={'item' + index} className="list-result" href={list.webUrl} target="_blank">
            <img className="list-result-thumbnail" src={getThumbnail(list)} alt={list.webTitle} />
            <p className="list-result-text">{list.webTitle}</p>
            <div className="badge-container">
              {list.pillarName && <span className="badge badge-default badge-round">{list.pillarName}</span>}
              <span className="badge badge-purple badge-round">{list.source}</span>
            </div>
          </a>
        ))}
      </div>
    </>
  )
}

export default ListOfNews
