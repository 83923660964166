import * as React from 'react'

const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="footer-text">Théo</div>
      </div>
    </>
  )
}

export default Footer
