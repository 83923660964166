import * as React from 'react'
import { Link, useNavigate } from 'react-router-dom'

const Header = () => {
  return (
    <>
      <div className="header">
        <Link className="header-title" to="/">
          Nine months ago
        </Link>
      </div>
    </>
  )
}

export default Header
